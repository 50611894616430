<template>
	<highcharts
		:options="option"
		:updateArgs="[true, true]"
	/>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

export default {
	computed: {
		option() {
			return this.mergeDeep(this.default_mini, this.options)
		},
	},
	data() {
		return {
			default_mini: {
				credits: {
					enabled: false,
				},
				chart: {
					backgroundColor: this.$vuetify.theme.dark ? `#1E1E1E` : `#fff`,
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false
						},
						dataLabels: {
							color: this.$vuetify.theme.dark ? `#fff` : `#333333`,
							shadow: false,
						},
					}
				},
				title:{
					style: {
						color: this.$vuetify.theme.dark ? `#fff` : `#333333`,
					},
					text:''
				},
				subtitle: {
					style: {
						color: this.$vuetify.theme.dark ? `rgba(255, 255, 255, 0.7)` : `#333333`,
					},
				},
				tooltip: {
					crosshairs: true,
					shared: true,
				},
				xAxis: {
					type: `datetime`,
					labels: {
						style: {
							color: this.$vuetify.theme.dark ? `rgba(255, 255, 255, 0.7)` : `#333333`,
						},
					},
				},
				yAxis: [{
					title: {
						enabled: false,
					},
					labels: {
						style: {
							color: this.$vuetify.theme.dark ? `rgba(255, 255, 255, 0.7)` : `#333333`,
						},
					},
				}],
			},
		}
	},
	name: `MaterialHighchart`,
	methods: {
		mergeDeep(...objects) {
			const isObject = obj => obj && typeof obj === 'object'
			return objects.reduce((prev, obj) => {
				Object.keys(obj).forEach(key => {
					const pVal = prev[key]
					const oVal = obj[key]
					if (Array.isArray(pVal) && Array.isArray(oVal)) { prev[key] = pVal.concat(...oVal) }
					else if (isObject(pVal) && isObject(oVal)) { prev[key] = this.mergeDeep(pVal, oVal) }
					else { prev[key] = oVal }
				})
				return prev
			}, {})
		},
	},
	props: ['options'],
}
</script>