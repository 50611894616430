<template>
	<v-dialog v-model="show" max-width="500" @keydown.esc="cancel">
		<v-card>
			<v-autocomplete
				v-if="show"
				:items="result"
				:loading="loading"
				:search-input.sync="search"
				no-filter
				placeholder="Search..."
				return-object
				class="px-4"
				autofocus
				:menu-props="{ maxWidth: `468px` }"
			>
				<template slot="item" slot-scope="{ item }">
					<v-list-item
						:key="item.id"
						@click="selectItem(item)"
					>
						<Avatar
							:source="detectSource(item)"
							:sub-source="detectSubSource(item)"
							class="mr-2"
						/>

						<v-list-item-content v-if="item.__type === `profile`">
							<v-list-item-subtitle class="font-weight-bold">
								<span class="mr-1">{{ item.display_name }}</span>
								<span v-if="item.claimed" class="primary--text mr-1">
									<svg-icon icon="verified" />
								</span>
								<span v-if="item.climate_dialogue && item.climate_dialogue.enabled" class="primary--text mr-1">
									<svg-icon icon="climate-dialogue" />
								</span>
							</v-list-item-subtitle>
							<v-list-item-subtitle>
								@{{ item.at_name }} ({{ item.type }})
							</v-list-item-subtitle>
						</v-list-item-content>


						<v-list-item-content v-if="item.__type === `post` && item.type === `news`">
							<v-list-item-subtitle class="font-weight-bold">News - {{ item.news_publisher_name }}</v-list-item-subtitle>
							<v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
							<v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
						</v-list-item-content>


						<v-list-item-content v-if="item.__type === `post` && item.type !== `news`">
							<v-list-item-subtitle class="font-weight-bold">{{ item.title }}</v-list-item-subtitle>
							<v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
						</v-list-item-content>


						<v-list-item-content v-if="item.__type === `comment`">
							<v-list-item-subtitle class="font-weight-bold">Comment</v-list-item-subtitle>
							<v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
							<v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-autocomplete>
		</v-card>
	</v-dialog>
</template>

<script>
import { globalMixIn } from '@/App'

export default {
	data: () => ({
		show: false,
		result: [],
		search: '',
		type: null,
		loading: false,
	}),
	name: `DialogSearch`,
	methods: {
		open(args) {
			this.reset()

			// If you know the at_name, reply the user without displaying the dialog
			if (args && args.at_name) {
				this.accountSearch(args)
			} else {
				this.result = []
				this.show = true
			}

			if (args && args.type) this.type = args.type

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.show = false
		},
		accept(item) {
			this.resolve(item)
			this.show = false
		},
		cancel() {
			this.reject()
			this.show = false
		},
		cancelSearch() {
			this.showSearch = false
			this.search = ``
		},
		selectItem(item) {
			this.cancelSearch()
			this.accept(item)
		},
		getType() {
			return this.type ?? this.types
		},
		isTypeAllowed(type) {
			if (this.getType() === `all`) return true
			if (this.getType() === type) return true
			if (this.getType() === `private` && [`private`,`accounts`].includes(type)) return true
			if (this.getType() === `public` && [`public`,`accounts`].includes(type)) return true
			if (this.getType() === `accounts` && [`public`,`private`].includes(type)) return true
			return false
		},
		detectSource(item) {
			if (item.__type === `profile`) return item.small_profile_image || `private-profile`
			if (item.__type === `comment`) return item.small_profile_image || `private-profile`
			if (item.__type === `post` && item.type === `news`) return item.image_url || `private-profile`
			if (item.__type === `post` && item.type !== `news`) return item.small_profile_image || `private-profile`
		},

		detectSubSource(item) {
			if (typeof item === `string`) {
				if (item.startsWith(`http`)) return item
				else return item
			}

			//if (item.__type === `profile`) return item.small_profile_image || `private-profile`
			if (item.__type === `comment`) return `comment`
			if (item.__type === `post` && item.type === `news`) return item.image_url || `private-profile`
			if (item.__type === `post` && item.type !== `news`) {
				if (item.review) return item.review.type
				return `pencil`
			}
		},
		accountSearch(args) {
			this.$httpInt.get(`/v2/app/account/${args.at_name}`)
				.then(res => this.accept(res.data.result))
				.catch(() => {
					this.result = []
					this.show = true
				})
		},
		makeSearch(value) {
			if (!value) this.result = []

			if (this.loading) return
			this.loading = true

			Promise.all([
				this.$httpInt.get(`/v2/app/account/?email=${value}`),
				this.$httpInt.get(`/v2/app/account/?q=${value}`),
				this.$httpInt.get(`/v2/app/account/?q=${value}&type=public`),
				this.$httpInt.get(`/v2/app/posts/?q=${value}&items_per_page=50`),
				this.$httpInt.get(`/v2/app/comments/?q=${value}&per_page=50`),
			]).then(data => {
				let list = []
				data.forEach((o, i) => {
					if (i === 0 && this.isTypeAllowed(`accounts`)) list = [...list, ...o.data.result.map(t => ({...t, __type: `profile`})).filter(a => this.isTypeAllowed(a.type))].sort((a,b) => {
						if (a.claimed || b.claimed) return !!b.claimed - !!a.claimed
						else return b.rank - a.rank
					})
					if (i === 1 && this.isTypeAllowed(`accounts`)) list = [...list, ...o.data.result.map(t => ({...t, __type: `profile`})).filter(a => this.isTypeAllowed(a.type))].sort((a,b) => {
						if (a.claimed || b.claimed) return !!b.claimed - !!a.claimed
						else return b.rank - a.rank
					})
					if (i === 2 && this.isTypeAllowed(`accounts`)) list = [...list, ...o.data.result.map(t => ({...t, __type: `profile`})).filter(a => this.isTypeAllowed(a.type))].sort((a,b) => {
						if (a.claimed || b.claimed) return !!b.claimed - !!a.claimed
						else return b.rank - a.rank
					})
					if (i === 3 && this.isTypeAllowed(`posts`)) list = [...list, ...o.data.result.map(t => ({...t, __type: `post`}))]
					if (i === 4 && this.isTypeAllowed(`comment`)) list = [...list, ...o.data.result.map(t => ({...t, __type: `comment`}))]
				})
				this.result = list
				this.loading = false
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
	},
	mixins: [globalMixIn],
	props: {
		types: {
			type: String,
			default: 'all'
		},
	},
	watch: {
		search(value) {
			value && this.$debounce(() => this.makeSearch(value), 300)
		}
	},
}
</script>