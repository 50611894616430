<template>
	<v-dialog v-model="show" width="600" @keydown.esc="cancel" persistent>
		<v-card>
			<v-card-title>
				Comment
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<ValidationObserver
				ref="comment"
				@submit.prevent="create"
			>
				<form autocomplete="off">
					<v-card-text v-if="item && !item.type" :class="`pa-6 pt-2 grey ${this.$vuetify.theme.dark ? `darken-3` : `lighten-3`}`">
						<span class="font-weight-bold">Reply to</span>
						<v-list-item class="pl-0">
							<Avatar :source="item.author.profile_image || `private-profile`" class="mr-2"></Avatar>
							<v-list-item-content class="pa-0">
								<v-list-item-title class="subtitle-2">
									<div>
										{{ item.author.display_name }}
									</div>
									<div class="grey--text text-caption">
										{{ dayjs(item.date_created).fromNow() }}
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<span>{{ item.text }}</span>
					</v-card-text>

					<v-card-text v-if="author" class="pl-6 pt-0">
						<v-row>
							<v-col class="pb-0">
								<v-list-item class="pl-0">
									<Avatar :source="author.profile_image || `private-profile`" class="mr-2"></Avatar>
									<v-list-item-content class="pa-0">
										<v-list-item-title class="subtitle-2 d-flex">
											<span class="align-self-center">
												{{ author.display_name }}
											</span>
											<Button
												text
												small
												class="ml-auto align-self-center"
												@click="changeAuthor"
											>
												Change
											</Button>
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pt-0">
								<VTextareaValidation
									autofocus
									rules="required"
									v-model="comment"
									label="Comment"
									@click:append-outer="create"
									:append-outer-icon="loading ? `` : `mdi-send`"
									rows="1"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="py-0">
								<span class="text-caption">Templates</span>
								<div class="d-flex">
									<div><template v-for="template in templates">
										<Button
											v-if="allowTemplate(template)"
											:key="template.id"
											small class="mr-1 mt-1"
											@click="renderTemplate(template)"
										>{{ template.value.name }} ({{ template.value.language }})</Button>
									</template></div>
									<v-spacer />
									<Button class="mr-1 mt-1" small @click="editTemplates"><svg-icon icon="pencil" /></Button>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>
		</v-card>

		<Search types="accounts" ref="search" />
		<Templates ref="templates" />

	</v-dialog>
</template>

<script>
import { globalMixIn } from '@/App.vue'
import VTextareaValidation from '@/components/validation/VTextarea'
import Search from '@/components/dialogs/Search'
import Templates from '@/components/dialogs/Templates'
import dayjs from "dayjs"
import { mapGetters } from 'vuex'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
	components: {
		VTextareaValidation,
		Search,
		Templates,
	},
	computed: {
		...mapGetters([`getAdmin`]),
		form() {
			let data = {
				post_id: this.item.post_id || this.item._id,
				parent_id: this.item.post_id && this.item._id || null,
				text: this.comment,
				author: this.author.at_name,
			}

			return data
		},
	},
	data: () => ({
		show: false,
		comment: null,
		loading: false,
		item: null,
		post: null,
		author: null,
		templates: [],
	}),
	name: `CreateComment`,
	methods: {
		open(item) {
			this.reset()
			this.loadTemplates()
			this.item = item
			this.show = true

			if (item.post_id) {
				this.$httpInt.get(`/v2/app/posts/${item.post_id}`)
					.then(res => this.post = res.data.result)
			} else this.post = item

			this.loadInitAuthor()

			if (this.item && this.item.depth > 0) {
				this.comment = `@${this.item.author.at_name} `
			}

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		loadTemplates() {
			this.$httpInt.get(`/v2/templates?per_page=1000`)
				.then(res => this.templates = res.data.result)
		},
		close() {
			this.show = false
		},
		accept() {
			this.resolve()
			this.show = false
		},
		cancel() {
			this.reject()
			this.close()
		},
		loadInitAuthor() {
			this.$httpInt.get(`/v2/app/account/WeDontHaveTime`)
				.then(res => this.author = res.data.result)
		},
		changeAuthor() {
			this.$refs.search.open()
				.then(o => this.author = o)
				.catch(() => {})
		},
		getReviewType(type) {
			if (type === `bomb`) return `warning`
			else return type
		},
		editTemplates() {
			this.$refs.templates.open()
				.then(() => {})
				.catch(() => {})
				.then(() => this.loadTemplates())
		},
		renderTemplate(template) {
			let body = template.value.body

			if (this.post.author) body = body.replaceAll("{{author.name}}", this.post.author.display_name)
			if (this.post.recipient) body = body.replaceAll("{{recipient.name}}", this.post.recipient.display_name)
			if (this.post.review) body = body.replaceAll("{{review.type}}", `climate ${this.getReviewType(this.post.review.type)}`)
			if (this.post.review) body = body.replaceAll("{{review.url}}", `${this.$appUrl}/posts/${this.post.id}`)
			if (this.post.agrees) body = body.replaceAll("{{agrees}}", this.post.agrees)
			if (this.post.views) body = body.replaceAll("{{views}}", this.post.views)
			if (this.post.shares) body = body.replaceAll("{{shares}}", this.post.shares)
			if (this.post.comments) body = body.replaceAll("{{comments}}", this.post.comments)
			if (this.post.title) body = body.replaceAll("{{title}}", this.post.title)
			if (this.post.text) body = body.replaceAll("{{content}}", this.post.text)
			body = body.replaceAll("{{admin.name}}", this.getAdmin().name)
			this.comment = body
		},
		create() {
			if (this.loading) return

			this.loading = true
			this.$httpInt.post(`/v2/app/comments/`, this.form)
				.then(() => this.accept())
				.finally(() => this.loading = false)
		},
		allowTemplate(item) {
			if (item.value.type !== `comment`) return false
			if (!item.value.filter) return true
			
			if (this.item.review) {
				if (item.value.filter === `climate-love` && this.item.review.type === `love`) return true
				else if (item.value.filter === `climate-idea` && this.item.review.type === `idea`) return true
				else if (item.value.filter === `climate-warning` && this.item.review.type === `warning`) return true
				return false
			}

			return true
			//return false
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
			//this.$nextTick(() => this.$refs.comment.reset())
		},
	},
	props: ['profile'],
	mixins: [globalMixIn],
}
</script>