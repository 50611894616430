<template>
	<v-dialog v-model="show" width="800" @keydown.esc="cancel" persistent>
		<v-card>
			<v-card-title>
				Templates
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<v-card-text>
				<v-row>
					<v-col>
						You can edit all your templates in this view.
					</v-col>
				</v-row>
			</v-card-text>

			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th width="60px">Lang</th>
						<th>Name</th>
						<th>Type</th>
						<th>Filter</th>
						<th width="80px"><Button small @click="create"><svg-icon icon="plus" /></Button></th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in templates"
						:key="item.id"
					>
						<td>{{ item.value.language }}</td>
						<td>{{ item.value.name }}</td>
						<td>{{ item.value.type }}</td>
						<td>{{ item.value.filter }}</td>
						<td>
							<div class="d-flex">
								<Button text small fab @click="editTemplate(item)"><svg-icon icon="pencil" /></Button>
								<Button text small fab @click="deleteTemplate(item)"><svg-icon icon="trash-can" /></Button>
							</div>
						</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>

		<Template ref="template" />
	</v-dialog>
</template>

<script>
import { globalMixIn } from '@/App'
import { mapGetters } from 'vuex'
import Template from '@/components/dialogs/Template'

export default {
	components: {
		Template,
	},
	computed: {
		...mapGetters([`getAdmin`]),
		form() {
			let data = { text: this.comment }
			if (this.item && !this.item.type) data.parent_id = this.item.id
			return data
		},
	},
	data: () => ({
		show: false,
		item: null,
		templates: [],
	}),
	name: `DialogTemplates`,
	methods: {
		open(item) {
			this.reset()
			this.item = item
			this.show = true

			this.loadTemplates()

			if (this.item && this.item.depth > 0) {
				this.comment = `@${this.item.author.at_name} `
			}

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.show = false
		},
		accept() {
			this.resolve()
			this.show = false
		},
		cancel() {
			this.reject()
			this.close()
		},
		loadTemplates() {
			this.$httpInt.get(`/v2/templates?per_page=1000`)
				.then(res => this.templates = res.data.result)
		},
		editTemplate(item) {
			this.$refs.template.open(item)
				.then(() => {})
				.catch(() => {})
				.then(() => this.loadTemplates())
		},
		deleteTemplate(item) {
			this.$root.$confirm(`Delete template`, `Are you sure you want to delete template "${item.value.name}"?`)
				.then(confirm => confirm && this.$httpInt.delete(`/v2/templates/${item.id}`).then(() => this.loadTemplates()))
		},
		create() {
			this.$refs.template.open()
				.then(() => {})
				.catch(() => {})
				.then(() => this.loadTemplates())
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
			//this.$nextTick(() => this.$refs.comment.reset())
		},
	},
	mixins: [globalMixIn],
}
</script>