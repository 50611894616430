<template>
	<div>
		<v-row no-gutters>
			<v-col>
				<v-row no-gutters>
					<v-col>
						<div>Type: <span class="font-weight-bold">{{getMessageType}}</span></div>
						<div>Status: <span class="font-weight-bold">{{getStatus}}</span></div>
					</v-col>
					<v-col align="end">
						<div>Created: <span class="font-weight-bold">{{dayjs(message.creation_date).format(`YYYY-MM-DD HH:mm:ss`)}}</span></div>
						<div>Expire: <span class="font-weight-bold">{{dayjs(message.expired_at).format(`YYYY-MM-DD HH:mm:ss`)}}</span></div>
						<div v-if="message.read_at">Read: <span class="font-weight-bold">{{dayjs(message.read_at).format(`YYYY-MM-DD HH:mm:ss`)}}</span></div>
						<div v-if="message.cta_clicked_at">CTA: <span class="font-weight-bold">{{dayjs(message.cta_clicked_at).format(`YYYY-MM-DD HH:mm:ss`)}}</span></div>
						<div v-if="message.closed_at">Closed: <span class="font-weight-bold">{{dayjs(message.closed_at).format(`YYYY-MM-DD HH:mm:ss`)}}</span></div>

						<template v-if="message.group_meta && message.group_meta._id">
							<div>Read: <span class="font-weight-bold">{{ message.read }} of {{ message.created }} ({{ Math.round(message.read/message.created*100) }}%)</span></div>
							<div>CTA: <span class="font-weight-bold">{{ message.cta }} of {{ message.created }} ({{ Math.round(message.cta/message.created*100) }}%)</span></div>
							<div>Closed: <span class="font-weight-bold">{{ message.closed }} of {{ message.created }} ({{ Math.round(message.closed/message.created*100) }}%)</span></div>
						</template>
					</v-col>
				</v-row>

				<div
					align="center"
					class="pt-6"
				>
					<v-img max-width="150" max-height="150" :src="message.image_url" />
					<h3 class="py-3">{{ message.header }}</h3>
					<p class="pt-3">{{ message.body_text }}</p>
					<Button target="_blank" :link="true" :href="message.cta_link">{{message.cta_button}}</Button>
					<p class="pa-3 grey--text">CTA Url: {{ message.cta_link }}</p>
					<v-divider class="my-6" />
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		computed: {
			getMessageType() {
				if (this.message.message_type === 0) return `Manual`
				if (this.message.message_type === 1) return `Automatic`
				if (this.message.message_type === 2) return `Modal`
				if (this.message.message_type === 3) return `Private`
				return ``
			},
			getStatus() {
				if (this.message.message_type === 0) return `Active`
				if (this.message.message_type === 1) return `Inactive`
				if (this.message.message_type === 2) return `Closed`
				return ``
			},
		},
		name: `ComponentMessage`,
		props: [`message`],
		mixins: [globalMixIn],
	}
</script>