<template>
	<v-dialog v-model="show" width="600" @keydown.esc="cancel" persistent>
		<v-card>
			<v-card-title>
				Mail
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<ValidationObserver
				ref="email"
				@submit.prevent="create"
			>
				<form autocomplete="off">
					<v-card-text class="pl-6 pt-0">
						<v-row>
							<v-col>
								<VTextFieldValidation
									rules="required"
									v-model="to"
									label="To"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextFieldValidation
									rules="required"
									v-model="subject"
									label="Subject"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextareaValidation
									rules="required"
									v-model="body"
									label="Body"
									rows="1"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="d-flex">
								<v-spacer />
								<Button type="submit">
									Send
								</Button>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="py-0">
								<span class="text-caption">Templates</span>
								<div class="d-flex">
									<template v-for="template in templates">
										<Button
											v-if="allowTemplate(template)"
											:key="template.id"
											small class="mr-1"
											@click="renderTemplate(template)"
										>{{ template.value.name }} ({{ template.value.language }})</Button>
									</template>
									<v-spacer />
									<Button small @click="editTemplates"><svg-icon icon="pencil" /></Button>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>
		</v-card>

		<Templates ref="templates" />

	</v-dialog>
</template>

<script>
import { globalMixIn } from '@/App.vue'
import VTextareaValidation from '@/components/validation/VTextarea'
import VTextFieldValidation from '@/components/validation/VTextField'
import Templates from '@/components/dialogs/Templates'
import dayjs from "dayjs"
import { mapGetters } from 'vuex'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
	components: {
		VTextareaValidation,
		VTextFieldValidation,
		Templates,
	},
	computed: {
		...mapGetters([`getAdmin`]),
	},
	data: () => ({
		show: false,
		body: null,
		subject: null,
		loading: false,
		to: null,
		item: null,
		author: null,
		target: null,
		templates: [],
	}),
	name: `CreateMail`,
	methods: {
		open(item, target) {
			this.reset()
			this.loadTemplates()
			this.item = item
			this.show = true
			this.target = target

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		loadTemplates() {
			this.$httpInt.get(`/v2/templates?per_page=1000`)
				.then(res => this.templates = res.data.result)
		},
		close() {
			this.show = false
		},
		accept() {
			this.resolve()
			this.show = false
		},
		cancel() {
			this.reject()
			this.close()
		},
		getReviewType(type) {
			if (type === `bomb`) return `warning`
			else return type
		},
		editTemplates() {
			this.$refs.templates.open()
				.then(() => {})
				.catch(() => {})
				.then(() => this.loadTemplates())
		},
		renderTemplate(template) {
			this.body = this.renderString(template.value.body)
			this.subject = this.renderString(template.value.subject)
		},
		renderString(string) {
			if (this.item.author !== 'undefined') string = string.replaceAll("{{author.name}}", this.item.author.display_name)
			if (this.item.recipient !== 'undefined') string = string.replaceAll("{{recipient.name}}", this.item.recipient.display_name)
			if (this.item.review !== 'undefined') string = string.replaceAll("{{review.type}}", `climate ${this.getReviewType(this.item.review.type)}`)
			if (this.item.review !== 'undefined') string = string.replaceAll("{{review.url}}", `${this.$appUrl}/posts/${this.item._id}`)
			if (this.item.agrees !== 'undefined') string = string.replaceAll("{{agrees}}", this.item.agrees)
			if (this.item.comments !== 'undefined') string = string.replaceAll("{{comments}}", this.item.comments)
			if (this.item.shares !== 'undefined') string = string.replaceAll("{{shares}}", this.item.shares)
			if (this.item.views !== 'undefined') string = string.replaceAll("{{views}}", this.item.views)
			if (this.item.title !== 'undefined') string = string.replaceAll("{{title}}", this.item.title)
			if (this.item.text !== 'undefined') string = string.replaceAll("{{content}}", this.item.text)
			string = string.replaceAll("{{admin.name}}", this.getAdmin().name)
			return string
		},
		create() {
			const body = this.body.replace(/\r\n|\r|\n/g, "%0D%0A")
			window.open(`mailto:${this.to}?subject=${this.subject}&body=${body}`)
		},
		allowTemplate(item) {
			if (!item.value.type || !item.value.type.startsWith(`email`)) return false
			if (item.value.type === `email-recipient` && this.target !== `email-recipient`) return false
			if (item.value.type === `email-author` && this.target !== `email-author`) return false
			if (!item.value.filter) return true

			if (this.item.review) {
				if (item.value.filter === `climate-love` && this.item.review.type === `love`) return true
				else if (item.value.filter === `climate-idea` && this.item.review.type === `idea`) return true
				else if (item.value.filter === `climate-warning` && this.item.review.type === `warning`) return true
				return false
			}
			return false
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
			//this.$nextTick(() => this.$refs.email.reset())
		},
	},
	props: ['profile'],
	mixins: [globalMixIn],
}
</script>