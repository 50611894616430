<template v-if="comments" >
	<div class="comment-root">
		<v-col :style="child && `border-left:1px solid #eaebf1;` || ``" v-for='(comment, i) in comments.slice()' :key="i">
			<v-col
				:class="$route.query.comment && $route.query.comment === comment._id ? `highlight` : ``"
			>
				<template v-if="!comment.is_deleted">
					<a
						class="font-weight-bold"
						v-on:click="$router.push({name: `Profile`, params: {id: comment.author.at_name}})"
					>{{comment.author.at_name}}</a>

					<TimeAgo :timestamp="comment.date_created" include-dot />

					<div>{{comment.text}}</div>
					<span class="mr-1" v-if="comment.agrees">
						<svg-icon icon="agree" /> {{comment.agrees.agrees}}
					</span>

					<Button text icon class="mr-1" @click="pinComment(comment)" v-if="$authorized([`manager`,`marketing`])">
						<svg-icon v-if="comment.is_pinned" icon="pin-fill" />
						<svg-icon v-else icon="pin" />
					</Button>
					<v-menu offset-y v-if="$authorized([`manager`,`marketing`])">
						<template v-slot:activator="{ on, attrs }">
							<Button
								text
								icon
								class="mr-1"
								v-bind="attrs"
								v-on="on"
							>
								<svg-icon icon="menu-dots" />
							</Button>
						</template>
						<v-list>
							<template v-if="$authorized([`manager`])">
								<v-list-item @click="createComment(comment)">
									<v-list-item-title>Reply...</v-list-item-title>
								</v-list-item>

								<v-divider />
							</template>

							<v-list-item @click="blacklistComment(comment)" >
								<v-list-item-title class="error--text">
									Blacklist comment...
								</v-list-item-title>
							</v-list-item>

							<v-list-item @click="deleteComment(comment)" >
								<v-list-item-title class="error--text">
									Delete comment...
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>

				</template>
				<template v-else>
					DELETED
				</template>
			</v-col>
			<template v-if='comment.children'>
				<node-list class='py-0 pl-3' :child="true" :comments='comment.children'/>
			</template>
		</v-col>

		<CreateComment ref="createComment" />

	</div>
</template>

<script>
	import { globalMixIn } from '@/App'
	import CreateComment from "@/components/dialogs/CreateComment"
	import TimeAgo from "@/components/elements/TimeAgo";

	export default {
		components: {
			TimeAgo,
			CreateComment,
		},
		name: 'node-list',
		props: ['comments', 'child'],
		methods: {
			createComment(item) {
				this.$refs.createComment.open(item)
					.then(() => {})
					.catch(() => {})
			},
			blacklistComment (comment) {
				this.$root.$confirm(`Blacklist comment`, `This will hide the comment on the platform and add an abuse record for the user. Are you sure you want to continue?`, { color: `error` })
					.then(confirm => {
						if (confirm) {
							this.$httpInt.post(`/v2/app/legacy/admin/reports/admin-resolution`, {
								entity_id: comment.id || comment._id,
								entity_type: `comment`,
								admin_resolution: `blacklisted`,
							})
								.then(res => this.$root.$snackbar(res.data.message))
						}
					})
			},
			deleteComment (comment) {
				this.$root.$confirm(`Delete`, `Are you sure?`, { color: `error` })
					.then((confirm) => {
						if (confirm) {
							this.$httpInt.delete(`/v2/app/comments/${comment._id}`)
								.then(() => {
									comment.is_deleted = true
									this.$root.$snackbar(`Comment deleted`)
								})
						}
					})
			},
			pinComment (comment) {
				if (!comment.is_pinned) {
					this.$root.$confirm(`Pin comment`, `Are you sure?`)
						.then((confirm) => {
							if (confirm) {
								this.$httpInt.put(`/v2/app/legacy/admin/comments/${comment._id}/pin`)
									.then(() => {
										for (const item of this.comments) item.is_pinned = false
										comment.is_pinned = true
										this.$root.$snackbar(`Comment pinned`)
									})
							}
						})
				} else {
					this.$root.$confirm(`Unpin comment`, `Are you sure?`)
						.then((confirm) => {
							if (confirm) {
								this.$httpInt.delete(`/v2/app/legacy/admin/comments/${comment._id}/pin`)
									.then(() => {
										comment.is_pinned = false
										this.$root.$snackbar(`Comment unpinned`)
									})
							}
						})
				}

			},
		},
		mixins: [globalMixIn],
	}
</script>