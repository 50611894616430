<template>
	<div>
		<v-list-item v-if="post.author" class="pt-2">
			<Avatar
				v-if="post.author && post.author.profile_image"
				:source="post.author.profile_image"
				class="mr-2"
			/>

				<v-list-item-content class="pa-0">
					<v-list-item-title class="subtitle-2">
						<div v-if="post.author">
							<a
									class="font-weight-bold"
									v-on:click="$router.push({name: `Profile`, params: {id: post.author.at_name}})"
									v-text="post.author.display_name"
							/>
						</div>
						<TimeAgo :timestamp="post.date_created" />
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<div>

			<v-list-item v-if="post.recipient">
				<Avatar
					:source="post.recipient.profile_image"
					:sub-source="post.review.type"
					class="mr-2"
				/>

				<v-list-item-content pa-0>
					<v-list-item-title class="subtitle-2">
						<div v-if="post.recipient">
							<a
								class="font-weight-bold"
								v-on:click="$router.push({name: `Profile`, params: {id: post.recipient.at_name}})"
							>{{ post.recipient.display_name }}</a>
						</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content class="pt-1" style="cursor: pointer" @click="$router.push({name: 'Post', params: {id: post._id}})">
					<v-list-item-title class="subtitle-1 font-weight-bold">
						{{ post.title }}
					</v-list-item-title>
					<span class="body-2">
						{{ post.text }}
					</span>
					<v-list-item-subtitle>
						<v-img
							v-if="post.image_urls"
							:src="post.image_urls[0]"
							aspect-ratio="1"
							class="grey lighten-2 mt-2"
							max-height="300"
						/>
						<v-img
							v-else-if="post.image_url"
							:src="post.image_url"
							aspect-ratio="1"
							class="grey lighten-2 mt-2"
							max-height="300"
						/>
					</v-list-item-subtitle>

					<v-row>
						<v-col>
							<span class="pa-2">
								<svg-icon icon="agree" />
								{{ post.agrees }}
							</span>
						</v-col>
						<v-col class="text-right">
							<span class="pa-2">
								<svg-icon icon="eye" />
								{{ post.views }}
							</span>
							<span class="pa-2">
								<svg-icon icon="comment" />
								{{ post.comments }}
							</span>
							<span class="pa-2">
								<svg-icon icon="share" />
								{{ post.shares }}
							</span>
						</v-col>
					</v-row>

					<v-row no-gutters v-if="!preview">
						<v-col>
							<Categories :selected="post.categories" :item="post" />
						</v-col>
					</v-row>
					<v-divider v-else />

				</v-list-item-content>
			</v-list-item>
		</div>
	</div>
</template>

<script>
	import TimeAgo from "@/components/elements/TimeAgo";
	import Categories from "@/components/material/Categories";
	import {mapGetters} from "vuex";

	export default {
		components: {
			TimeAgo,
			Categories,
		},
		computed: {
			...mapGetters([`getCategories`]),
		},
		methods: {
			changeCategories(items) {
				this.$httpInt.put(`/v2/app/posts/${this.post._id}`, { categories: items })
			}
		},
		name: `ComponentPost`,
		props: {
			post: {
				type: Object,
				default: () => ({})
			},
			preview: {
				type: Boolean,
				default: false,
			}
		},
	}
</script>