<template>
	<v-container
			fluid
	>
		<v-alert
			v-if="post.is_deleted"
			prominent
			outlined
			text
			type="error"
		>
			<v-row align="center">
				<v-col class="grow">
					<h2>Deleted</h2>
					This content has been deleted
				</v-col>
			</v-row>
		</v-alert>

		<v-alert
			v-if="post.is_abuse"
			prominent
			outlined
			text
			type="error"
		>
			<v-row align="center">
				<v-col class="grow">
					<h2>Abused</h2>
					This content has been blacklisted
				</v-col>
			</v-row>
		</v-alert>

		<v-alert
			v-if="reports.length > 0"
			prominent
			outlined
			text
			type="warning"
		>
			<v-row align="center">
				<v-col class="grow">
					<h2>Reported</h2>
					This content has been reported <span class="font-weight-bold">{{ reports.length }} {{ reports.length > 1 ? `times` : `time`}}</span>
				</v-col>
				<v-col class="shrink text-no-wrap" v-if="!post.is_deleted">
					<Button medium :class="`ma-2 white ${this.$vuetify.theme.dark ? `black--text` : ``}`" @click="abuseResolve(`whitelisted`)">Whitelist</Button>
					<Button medium class="ma-2 black" dark @click="abuseResolve(`blacklisted`)">Blacklist</Button>
				</v-col>
			</v-row>
		</v-alert>

		<v-row justify="center">
			<v-col cols="8">
				<v-row>
					<v-col  class="pt-0">
						<v-card v-if="post">
							<v-card-text>
								<v-row no-gutters>
									<v-col class="d-flex">
										<span class="text-h5 align-self-center pr-4">
											{{ postTitle }}
										</span>
										<Button v-if="!post.is_deleted && !post.is_abuse" class="mr-4" text @click="openUrl(`/posts/${post._id}`)">View in web</Button>
										<v-spacer />
										<v-menu offset-y v-if="$authorized([`manager`,`marketing`])">
											<template v-slot:activator="{ on }">
												<Button
													icon
													v-on="on"
													class="ml-2"
												>
													<v-icon>mdi-dots-horizontal</v-icon>
												</Button>
											</template>
											<v-list>
												<template v-if="!post.is_abuse && !post.is_deleted">
													<v-list-item @click="pinPost" >
														<v-list-item-title>
															Pin...
														</v-list-item-title>
													</v-list-item>
													<v-list-item @click="pushPost" v-if="$authorized([`manager`,`marketing`])" >
														<v-list-item-title>
															Push notification...
														</v-list-item-title>
													</v-list-item>

													<v-list-item @click="createComment" v-if="$authorized([`manager`])" >
														<v-list-item-title>
															Comment post...
														</v-list-item-title>
													</v-list-item>

													<v-divider />
												</template>

												<v-list-item @click="sendSystemMessage">
													<v-list-item-title>Send system message...</v-list-item-title>
												</v-list-item>

												<v-list-item @click="sendToAuthor">
													<v-list-item-title>Mail author...</v-list-item-title>
												</v-list-item>

												<v-list-item @click="sendToRecipient">
													<v-list-item-title>Mail recipient...</v-list-item-title>
												</v-list-item>

												<template v-if="!post.is_abuse && !post.is_deleted">
													<v-divider />

													<v-list-item @click="blacklistPost" >
														<v-list-item-title class="error--text">
															Blacklist post...
														</v-list-item-title>
													</v-list-item>

													<v-list-item @click="deletePost" >
														<v-list-item-title class="error--text">
															Delete post...
														</v-list-item-title>
													</v-list-item>
												</template>
											</v-list>
										</v-menu>
									</v-col>
								</v-row>

								<Post :post="post" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-card>
							<v-tabs v-model="tab">
								<v-tab key="1">
									Comments
								</v-tab>
								<v-tab key="2" v-if="$authorized([`manager`,`marketing`])">
									Group Messages
								</v-tab>
							</v-tabs>

							<v-tabs-items v-model="tab">
								<v-card-text>
									<v-tab-item key="1">
										<template v-if="comments.length > 0">
											<CommentSection :comments="comments" />
										</template>
										<div v-else class="text-h6 text-center pa-8">
											No comments yet
										</div>

									</v-tab-item>

									<v-tab-item key="2">
										<template v-if="groupMessages.length > 0">
											<Message v-for="(message, i) in groupMessages" :key="i" :message="message" />
										</template>
										<div v-else class="text-h6 text-center pa-8">
											No group messages yet
										</div>
									</v-tab-item>
								</v-card-text>
							</v-tabs-items>
						</v-card>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="4">
				<v-row>
					<v-col  class="pt-0">
						<v-card v-if="post">
							<v-card-text>
								<Highchart :options="totalEngagement" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<Dialog ref="dialog">
			<v-list subheader v-if="post && post.agrees">
				<v-list-item
						v-for="item in agrees"
						:key="item.account.at_name"
						@click="$router.push({name: 'Profile', params: {id: item.account.at_name}})"
				>
					<v-list-item-avatar>
						<v-img :src="item.account.small_profile_image"></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title v-text="item.account.at_name"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</Dialog>

		<CreateComment ref="createComment" />
		<CreateMail ref="createMail" />
		<NewSystemMessages ref="systemMessages" />

	</v-container>

</template>



<script>
	import { globalMixIn } from '@/App'
	import CommentSection from '@/components/material/Comment'
	import Post from '@/components/material/Post'
	import Dialog from '@/components/material/Dialog'
	import CreateComment from "@/components/dialogs/CreateComment"
	import CreateMail from "@/components/dialogs/CreateMail"
	import Highchart from '@/components/material/Highchart'
	import NewSystemMessages from "@/components/dialogs/NewSystemMessages"
	import Message from '@/components/material/Message'

	export default {
		components: {
			CommentSection,
			Post,
			Dialog,
			Highchart,
			CreateComment,
			CreateMail,
			NewSystemMessages,
			Message,
		},
		computed: {
			postTitle() {
				if (!this.post) return ``
				if (this.post.type === `article`) return `Article`
				if (this.post.type === `post`) return `Post`
				if (this.post.type === `pledge`) return `Pledge`
				if (this.post.type === `review`) {
					if (this.post.review.type === `idea`) return `Climate Idea`
					if (this.post.review.type === `warning`) return `Climate Warning`
					if (this.post.review.type === `love`) return `Climate Love`
				}
				return ``
			},
		},
		data() {
			return {
				totalEngagement: {},
				reports: [],
				tab: null,
				comments: [],
				groupMessages: [],
				search: '',
				agrees: [],
				options: {},
				post: {
					age: ``,
					author: {
						at_name: ``,
						display_name: ``,
						profile_image: ``,
					},
					agrees: 0,
					views: 0,
					shares: 0,
					comments: 0,
					text: ``,
					title: ``,
					type: ``,
					review: {},
					recipient: {
						at_name: ``,
						display_name: ``,
						profile_image: ``,
					},
					is_deleted: false,
					is_draft: false,
					is_abuse: false,
					is_pinned: false,
				},
				headers: [
					{ text: 'Name', value: 'display_name' },
					{ text: 'User Name', value: 'at_name' },
					{ text: 'Type', value: 'type' },
					{ text: 'Actions', value: 'action', align: 'center', sortable: false },
				],
			}
		},
		methods: {
			abuseResolve(resolve) {
				this.$httpInt.post(`/v2/app/legacy/admin/reports/admin-resolution`, {
					entity_id: this.$route.params.id,
					entity_type: `post`,
					admin_resolution: resolve,
				})
					.then(res => {
						this.reports = []
						this.$root.$snackbar(res.data.message)
						if (resolve === `blacklisted`) this.post.is_abuse = true
					})
			},
			sendSystemMessage() {
				this.$refs.systemMessages.open({
					uuid: this.user_id,
					at_name: this.at_name,
					type: `post`,
					group_meta: {
						post_id: this.$route.params.id,
					},
				})
			},
			openUrl(url) {
				this.openInBrowser(this.$appUrl + url)
			},
			blacklistPost() {
				this.$root.$confirm('Blacklist post', 'This will hide the post on the platform and add an abuse record for the user. Are you sure you want to continue?', { color: 'error' })
					.then(confirm => confirm && this.abuseResolve(`blacklisted`))
			},
			deletePost() {
				this.$root.$confirm('Delete', 'Are you sure?', { color: 'error' })
					.then(confirm => {
						if (confirm) {
							this.$httpInt.delete(`/v2/app/posts/${this.$route.params.id}`)
								.then(() => {
									this.$root.$snackbar('Post deleted')
									this.setAsDeleted()
								})
						}
					})
			},
			pinPost() {
				this.$root.$confirm('Pin post', 'Are you sure?')
					.then((confirm) => {
						if (confirm) {
							this.$httpInt.post(`/v2/app/legacy/admin/feed/pin_post`, { post_id: this.$route.params.id })
								.then(() => this.$root.$snackbar('Post pinned'))
						}
					})
			},
			createComment() {
				this.$refs.createComment.open(this.post)
				.then(() => {})
				.catch(() => {})
			},
			pushPost () {
				this.$root.$confirm('Push post', 'Are you sure?')
					.then((confirm) => {
						if (confirm) {
							this.$httpInt.post(`/v2/app/legacy/admin/notifications/promote_post`, { post_id: this.$route.params.id, description:'' })
								.then(() => this.$root.$snackbar('Post pushed'))
						}
					})
			},
			sendToAuthor() {
				this.$refs.createMail.open(this.post, `email-author`)
			},
			sendToRecipient() {
				this.$refs.createMail.open(this.post, `email-recipient`)
			},
			setAsDeleted() {
				this.post.is_deleted = true
			},
			loadPost () {
				this.$httpInt.get(`/v2/app/posts/${this.$route.params.id}`)
					.then(res => {
						this.post = res.data.result

						this.$httpInt.get(`/v2/app/reports/${this.$route.params.id}`)
							.then(res => this.reports = res.data.result)

						this.$httpInt.get(`/v2/app/posts/${this.$route.params.id}/comments`)
							.then(res => this.comments = res.data.result)

						this.$httpInt.get(`/v2/app/group-messages?post_id=${this.$route.params.id}&sort_by_desc=true&sort_by=creation_date`)
							.then(res => this.groupMessages = res.data.result)
					})
			},
		},
		mixins: [globalMixIn],
		mounted () {
			this.loadPost()
			this.$httpInt.get(`/v2/app/posts/${this.$route.params.id}/activity`)
				.then(res => {
					this.totalEngagement = {
						chart: {
							type: `area`,
							height: `150px`,
						},
						plotOptions: {
							area: {
								stacking: `normal`,
								connectNulls: true,
								lineWidth: 0,
								marker: {
									lineWidth: 0,
								}
							}
						},
						yAxis: [
							{
								title: {
									text: ``,

								},
							}, {
								title: {
									text: ``,
								},
								opposite: true,
							}
						],
						series: res.data.result,
					}
				})
		},
		name: `CommunityPost`,
	}
</script>
