<template>
	<v-autocomplete
		:append-outer-icon="editing ? `` : `mdi-pencil`"
		@click:append-outer="editing = !editing"
		:items="getCategories()"
		:value="selected"
		item-text="name"
		item-value="name"
		small-chips
		multiple
		:readonly="!($authorized([`manager`,`marketing`]) && editing)"
		@input="changeCategories"
		filled
		rounded
		hide-details
		label="Categories"
	>
	</v-autocomplete>
</template>

<script>

import {mapGetters} from "vuex";

export default {
	computed: {
		...mapGetters([`getCategories`]),
	},
	data() {
		return {
			editing: false,
		}
	},
	methods: {
		changeCategories(items) {
			if (this.item.type.includes(`review`, `post`, `article`)) {
				this.$httpInt.put(`/v2/app/posts/${this.item._id}`, { categories: items })
			}

			if (this.item.type.includes(`public`, `private`)) {
				this.$httpInt.put(`/v2/app/account/${this.item.at_name}`, { categories: items })
			}
		}
	},
	name: `CategoriesFilter`,
	props: {
		selected: {
			type: Array,
			default: ()=>[]
		},
		item: {
			type: Object,
			default: () => ({})
		},
	},
}
</script>