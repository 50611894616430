<template>
  <v-dialog v-model="show" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar v-if="title" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <slot>No content to show</slot>
      <v-card-actions class="pt-0">
        <v-spacer />
        <Button type="submit" @click.native="accept">Ok</Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      show: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        width: 290,
        zIndex: 200
      }
    }),
    name: `MaterialDialog`,
    methods: {
      open() {
        this.show = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      close() {
        this.show = false
      },
      accept() {
        this.resolve(true)
        this.show = false
      },
      cancel() {
        this.resolve(false)
        this.show = false
      }
    }
  }
</script>