<template>
	<v-dialog v-model="show" width="500" @keydown.esc="cancel" persistent>
		<v-card v-if="item">
			<v-card-title>
				<span v-if="edit">Edit template</span>
				<span v-if="!edit">New template</span>
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<v-card-text class="pl-6 pt-0">
				<v-row v-if="!edit">
					<v-col>

						<VSelect
							v-model="item.value.type"
							label="Type"
							:items="[`comment`,`email`,`email-author`,`email-recipient`]"
						/>
					</v-col>
				</v-row>
			</v-card-text>

			<ValidationObserver
				ref="comment"
				@submit.prevent="saveComment"
				v-if="item.value && item.value.type === `comment`"
			>
				<form autocomplete="off">
					<v-card-text class="pl-6 pt-0">
						<v-row>
							<v-col>
								<VTextFieldValidation
									v-model="comment.value.name"
									label="Name"
								/>
							</v-col>
						</v-row>

						<v-row v-if="!edit">
							<v-col cols="10">
								<VSelectValidation
									v-model="comment.value.filter"
									label="Filter"
									:items="[``,`climate-love`,`climate-idea`,`climate-warning`]"
								/>
							</v-col>
							<v-col cols="2">
								<VSelectValidation
									v-model="comment.value.language"
									label="Language"
									:items="[`en`,`sv`]"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextareaValidation
									v-model="comment.value.body"
									label="Comment"
									rows="1"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0 d-flex">
								<v-spacer />
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">

										<Button
											v-bind="attrs"
											v-on="on"
											text
											class="mr-2"
										>
											<svg-icon icon="help" />
										</Button>
									</template>
									<span>
										<div>{{`\{\{author.name\}\}`}} = Author's name</div>
										<div>{{`\{\{recipient.name\}\}`}} = Recipient's name</div>
										<div>{{`\{\{review.type\}\}`}} = Type of Climate Review</div>
										<div>{{`\{\{review.url\}\}`}} = URL to campaign page</div>
										<div>{{`\{\{admin.name\}\}`}} = Name of you!</div>
										<div>{{`\{\{agrees\}\}`}} = Number of agrees</div>
										<div>{{`\{\{comments\}\}`}} = Number of comments</div>
										<div>{{`\{\{shares\}\}`}} = Number of shares</div>
										<div>{{`\{\{views\}\}`}} = Number of views</div>
										<div>{{`\{\{title\}\}`}} = Title of the post</div>
										<div>{{`\{\{content\}\}`}} = Content of the post</div>
									</span>
								</v-tooltip>

								<Button type="submit" :loading="loading">
									<span v-if="!edit">Create</span>
									<span v-if="edit">Save</span>
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>

			<ValidationObserver
				ref="email"
				@submit.prevent="saveEmail"
				v-if="item.value && item.value.type && item.value.type.startsWith(`email`)"
			>
				<form autocomplete="off">
					<v-card-text class="pl-6 pt-0">
						<v-row>
							<v-col>
								<VTextFieldValidation
									v-model="email.value.name"
									label="Name"
								/>
							</v-col>
						</v-row>

						<v-row v-if="!edit">
							<v-col cols="10">
								<VSelectValidation
									v-model="email.value.filter"
									label="Filter"
									:items="[``,`climate-love`,`climate-idea`,`climate-warning`]"
								/>
							</v-col>

							<v-col cols="2">
								<VSelectValidation
									v-model="email.value.language"
									label="Language"
									:items="[`en`,`sv`]"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextFieldValidation
									v-model="email.value.subject"
									label="Subject"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextareaValidation
									v-model="email.value.body"
									label="Body"
									rows="1"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0 d-flex">
								<v-spacer />
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">

										<Button
											v-bind="attrs"
											v-on="on"
											text
											class="mr-2"
										>
											<svg-icon icon="help" />
										</Button>
									</template>
									<span>
										<div>{{`\{\{author.name\}\}`}} = Author's name</div>
										<div>{{`\{\{recipient.name\}\}`}} = Recipient's name</div>
										<div>{{`\{\{review.type\}\}`}} = Type of Climate Review</div>
										<div>{{`\{\{review.url\}\}`}} = URL to campaign page</div>
										<div>{{`\{\{admin.name\}\}`}} = Name of you!</div>
										<div>{{`\{\{agrees\}\}`}} = Number of agrees</div>
										<div>{{`\{\{comments\}\}`}} = Number of comments</div>
										<div>{{`\{\{shares\}\}`}} = Number of shares</div>
										<div>{{`\{\{views\}\}`}} = Number of views</div>
										<div>{{`\{\{title\}\}`}} = Title of the post</div>
										<div>{{`\{\{content\}\}`}} = Content of the post</div>
									</span>
								</v-tooltip>

								<Button type="submit" :loading="loading">
									<span v-if="!edit">Create</span>
									<span v-if="edit">Save</span>
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>

		</v-card>
	</v-dialog>
</template>

<script>
import { globalMixIn } from '@/App'
import { mapGetters } from 'vuex'
import VTextFieldValidation from '@/components/validation/VTextField'
import VTextareaValidation from '@/components/validation/VTextarea'
import VSelectValidation from '@/components/validation/VSelect'

export default {
	components: {
		VTextFieldValidation,
		VTextareaValidation,
		VSelectValidation,
	},
	computed: {
		...mapGetters([`getAdmin`]),
	},
	data: () => ({
		show: false,
		loading: false,
		item: null,
		edit: null,
		comment: {
			value: {
				body: ``,
				filter: ``,
				name: ``,
				language: `en`,
			},
		},
		email: {
			value: {
				body: ``,
				filter: ``,
				subject: ``,
				name: ``,
				language: `en`,
			},
		},
	}),
	name: `DialogTemplate`,
	methods: {
		open(item) {

			this.reset()
			if (item) {
				this.item = item
				this.edit = true
			} else {
				this.item = {
					value: {}
				}
				this.mode = false
			}
			this.show = true

			if (item) {
				if (item.value.type === `comment`) this.comment = {...this.comment, ...item}
				if (item.value.type && item.value.type.startsWith(`email`)) this.email = {...this.email, ...item}
			}

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.show = false
		},
		accept() {
			this.resolve()
			this.show = false
		},
		cancel() {
			this.reject()
			this.close()
		},
		saveComment() {
			if (this.loading) return

			this.$refs.comment.validate().then(valid => {
				if (!valid) return
				this.loading = true

				if (this.edit) {
					this.$httpInt.patch(`/v2/templates/${this.item.id}`, this.comment)
						.then(() => this.accept())
						.then(() => this.loading = false)
				} else {
					this.comment.value.type = this.item.value.type
					this.$httpInt.post(`/v2/templates`, this.comment)
						.then(() => this.accept())
						.finally(() => this.loading = false)
				}
			})
		},
		saveEmail() {
			if (this.loading) return

			this.$refs.email.validate().then(valid => {
				if (!valid) return
				this.loading = true

				if (this.edit) {
					this.$httpInt.patch(`/v2/templates/${this.item.id}`, this.email)
						.then(() => this.accept())
						.then(() => this.loading = false)
				} else {
					this.email.value.type = this.item.value.type
					this.$httpInt.post(`/v2/templates`, this.email)
						.then(() => this.accept())
						.finally(() => this.loading = false)
				}
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
			//this.$nextTick(() => this.$refs.comment.reset())
		},
	},
	mixins: [globalMixIn],
}
</script>