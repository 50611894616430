<template>
	<v-tooltip v-if="timestamp" bottom>
		<template v-slot:activator="{ on, attrs }">
			<span
				v-bind="attrs"
				v-on="on"
				class="caption"
			>
				{{ includeDot && `• ` || `` }}{{ formatTimeAgo(timestamp) }}
			</span>
		</template>
		<span class="caption">{{ formatDate(timestamp) }}</span>
	</v-tooltip>
</template>

<script>

export default {
	methods: {
		formatDate(date) {
			let dateString = `HH:mm`
			if (!this.dayjs(date).isSame(this.dayjs(), 'day')) dateString += ` D MMM`
			if (!this.dayjs(date).isSame(this.dayjs(), 'year')) dateString += ` YYYY`
			return this.dayjs(date).format(dateString)
		},
		formatTimeAgo(date) {
			return this.dayjs(date).locale("en").fromNow(true)
		},
	},
	name: `TimeAgo`,
	props: {
		timestamp: {
			type: null
		},
		includeDot: {
			type: Boolean,
			default: false,
		},
	},
}
</script>