<template>
	<v-dialog v-model="show" max-width="500" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				New System Message
				<v-spacer />
				<Button icon text @click="close">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<ValidationObserver
				ref="form"
				@submit.prevent="send"
			>
				<form autocomplete="off">
					<v-card-text class="pa-6 pt-0">
						<v-row>

							<v-col v-if="type === `post`" cols="12">
								<VSelectValidation
									v-model="form.group_meta.type"
									label="Send message to a group of users"
									:items="[{name:`Everyone that has agreed on post`,value:`users_that_agreed_on_post`}]"
								/>
							</v-col>

							<template v-if="!type || type && form.group_meta.type">
								<v-col cols="12">
									<v-container>
										<VImage ref="image" allow-gif mode="messageCard" />
									</v-container>
								</v-col>

								<v-col cols="12">
									<VTextFieldValidation
										rules="required|max:30"
										v-model="form.header"
										label="Title"
									/>
								</v-col>

								<v-col cols="12">
									<VTextFieldValidation
										rules="required|max:110"
										v-model="form.body_text"
										label="Text"
									/>
								</v-col>

							</template>
						</v-row>

						<v-row v-if="!type || type && form.group_meta.type">
							<v-col cols="12">
								<VTextFieldValidation
									rules="required|max:15"
									v-model="form.cta_button"
									label="CTA Text"
								/>
							</v-col>

							<v-col cols="12">
								<VTextFieldValidation
									v-model="form.cta_link"
									label="CTA Link"
								/>
							</v-col>

							<v-col cols="12">
								<VTextFieldValidation
									rules="required"
									v-model="form.expired_at"
									label="Expire in X days"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-spacer />
								<Button
									type="submit"
									:loading="loading"
								>
									Send
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>
		</v-card>
	</v-dialog>
</template>

<script>
import VTextFieldValidation from '@/components/validation/VTextField'
import VSelectValidation from '@/components/validation/VSelect'
import VImage from '@/components/material/Image'

export default {
	components: {
		VTextFieldValidation,
		VSelectValidation,
		VImage,
	},
	data: () => ({
		show: false,
		expire_menu: false,
		message_types: [
			{
				name: `Manual`,
				value: 0,
			}
		],
		form: {
			body_text: null,
			header: null,
			title: null,
			message_type: 0,
			user_custom_ids: [],
			close_on_cta: true,
			group_meta: {},
			expired_at: 7,
			status: 0,
			image_url: null,
			cta_button: null,
			cta_link: null,
		},
		loading: false,
		type: null,
	}),
	name: `SystemMessages`,
	methods: {
		open(args) {
			this.reset()
			if (args && args.uuid) this.form.user_custom_ids.push(args.uuid)

			this.show = true
			this.type = args.type
			this.form.group_meta = args.group_meta ?? {}

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.show = false
			this.$refs.image.reset()
		},
		accept(item) {
			this.resolve(item)
			this.close()
		},
		cancel() {
			this.reject()
			this.close()
		},
		send() {
			this.$refs.form.validate().then(valid => {
				if (!valid || !this.$refs.image.exists()) return
				this.loading = true

				this.$refs.image.uploadImage()
					.then(res => {
						this.form.image_url = res.result.thumbnails.original.url
						this.$httpInt.post(`/v2/app/messages`, this.form)
							.then(() => this.close())
							.finally(() => this.loading = false)
					})
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
	},
}
</script>